<template>
  <div class="subscibtion-page">
    <PurchaseDynamic
      v-if="showDynamic"
      :packages="quizData.subscription_package"
      noCloseBtn
      :items="quizData.package_usp"
      :title="quizData.package_title"
      @onClose="onClose"
      showContent
    />
    <purchase v-else @onClose="onClose" @onSuccess="onClose" noCloseBtn />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Purchase from '../components/subscribe/Purchase.vue'
import { checkoutTypes } from '../data/constants'
import PurchaseDynamic from '@/components/subscribe/PurchaseDynamic.vue'

export default {
  components: { Purchase, PurchaseDynamic },
  computed: {
    ...mapState('quiz', ['quizData']),
    checkoutType() {
      return this.$route.query.checkout === checkoutTypes.PROMO
        ? checkoutTypes.PROMO
        : checkoutTypes.CHECKOUT
    },
    showDynamic() {
      return (
        this.$route.query.dynamic && Object.keys(this.quizData).length !== 0
      )
    },
  },
  created() {
    if (Object.keys(this.quizData).length === 0 && this.$route.query.dynamic) {
      this.$router.replace('/')
      return
    }
    const isPromo = this.$route.query.checkout === checkoutTypes.PROMO
    if (this.$route.params.checkoutId) {
      this.SET_CHECKOUT_TYPE(this.checkoutType)
      if (isPromo) {
        this.fetchCheckout(this.$route.params.checkoutId)
      } else this.fetchCheckoutById(this.$route.params.checkoutId)
    }
  },
  beforeUnmount() {
    this.SET_CHECKOUT_TYPE(null)
  },
  methods: {
    ...mapActions('checkout', ['fetchCheckoutById', 'fetchCheckout']),
    ...mapMutations('checkout', ['SET_CHECKOUT_TYPE']),
    onClose() {
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" scoped>
.subscibtion-page {
  min-height: 100vh;
  background: linear-gradient(341.28deg, #71b280 -33.77%, #134e5e 114.22%);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
